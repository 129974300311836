<template>
  <content-with-sidebar class="blog-wrapper">

    <!-- blogs -->
    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Поиск
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="mb-md-0 mb-2"
            >
              <label
                  for="search-query"
                  class="text-nowrap mr-50"
              >Запрос:</label>
              <b-form-input
                  id="search-query"
                  v-model="searchQuery"
                  placeholder="Savage Garden"
                  @keyup.enter="fetchArtistsList()"
                  autocomplete="off"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-table
          ref="refArtistsListTable"
          :items="artistsList"
          responsive
          :fields="tableColumns"
          primary-key="name"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
      >

        <!-- Column: Client -->
        <template #cell(artist)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                  size="32"
                  :text="avatarText(data.item.name)"
                  variant="primary"
              />
            </template>
            <span class="font-weight-bold d-block">
              {{ data.item.name }}
            </span>
            <ul v-if="data.item.tracks">
              <li
                  v-for="(track, index) in data.item.tracks"
                  :key="index"
              >
                <span class="font-weight-bold d-block">
              {{ track.name }} ({{ track.playcount }})

              <b-button
                  v-if="!track.db"
                  variant="flat-success"
                  class="btn-icon"
                  size="sm"
                  @click="addTrack(data.item, track)"
              >
              <feather-icon icon="PlusCircleIcon"/>
            </b-button>
            </span>
              </li>
            </ul>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <b-button
                v-if="!data.item.db"
                variant="success"
                class="btn-icon"
                size="sm"
                @click="addArtist(data.item)"
                :disabled="(disabled===data.item.mbid)"
            >
              <feather-icon icon="UserPlusIcon"/>
            </b-button>
            <b-button
                v-else
                :to="{name: 'artist-view', params: { id: data.item.db.id }}"
                variant="primary"
                class="btn-icon"
                size="sm"
            >
              <feather-icon icon="ArrowRightCircleIcon"/>
            </b-button>
            <b-button
                :href="data.item.url"
                variant="warning"
                class="btn-icon ml-1"
                size="sm"
                target="_blank"
            >
              <feather-icon icon="CastIcon"/>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-row>

    <!--/ blogs -->

  </content-with-sidebar>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import {avatarText, kFormatter} from '@core/utils/filter'
import ContentWithSidebar from '@core/layouts/components/content-with-sidebar/ContentWithSidebar.vue'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import musicStoreModule from "@/views/db-music/musicStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    ContentWithSidebar,
    vSelect,
  },
  data() {
    return {
      disabled: 0,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {
    kFormatter,
    addArtist(artist) {
      this.disabled = artist.mbid
      this.$http.post(`/artist`, {'title': artist.name}).then(res => {
        this.disabled = 0
        this.fetchArtistsList()
      })
    },
  },
  setup() {
    const STORE_MODULE_NAME = 'artists'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, musicStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })
    const searchQuery = ref(null)

    // Table Handlers
    const tableColumns = [
      { key: 'id', label: '#', sortable: false },
      { key: 'artist', sortable: false },
      { key: 'actions' },
    ]

    const artistsList = ref([])
    const fetchArtists = (...args) => store.dispatch('artists/searchArtists', ...args)

    const addTrack = (artist, track) => {
      let artist_id = null
      let artist_name = null
      if (artist && artist.db && artist.db.id) {
        artist_id = artist.db.id
      }
      if (artist && artist.name) {
        artist_name = artist.name
      }

      console.log(artist_id, artist_name)

      if (!artist_id) {
        store.dispatch(`artists/createArtist`, {title: artist_name}).then(response => {
          let new_artist_id = response.data.data.id
          store.dispatch('artists/addTrack', {id: new_artist_id, queryParams: {title: track.name}})
              .then(response => {
                console.log("addTrack + addArtist")
                if (response.data.success) {
                  fetchArtistsList()
                }
              })
        })
      } else {
        store.dispatch('artists/addTrack', {id: artist_id, queryParams: {title: track.name}})
            .then(response => {
              console.log("addTrack")
              if (response.data.success) {
                fetchArtistsList()
              }
            })
      }
    }

    const fetchArtistsList = () => {
      fetchArtists({
        q: searchQuery.value,
      })
      .then(response => {
        artistsList.value = response.data
      })
    }

    //fetchArtistsList()

    return {
      fetchArtists,
      tableColumns,
      searchQuery,
      artistsList,
      avatarText,
      fetchArtistsList,
      addTrack,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
